<template>
  <Icon :icon="mapIcon.remove" class="StatusBadgeRemove" />
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.StatusBadgeRemove {
  cursor: pointer;
}
</style>
